import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authentication: '',
  	menuName: "",
  	menuNav:'',
    navList:[]
  },
  mutations: {
    getVerifyCode(state,Authentication){
      state.Authentication = Authentication;
      localStorage.setItem('Authentication', state.Authentication);
    },
    setNav(state,setNav){
      console.log(setNav)
      state.navList = setNav;
    },
  	menuName (state,menuName) {
      state.menuName = menuName;
      console.log(menuName);
    },
    menuNav(state,menuNav){
			state.menuNav = menuNav;
    }
  },
  actions: {
  },
  modules: {
  }
})
