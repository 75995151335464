import { Vue, Component } from "vue-property-decorator";
import { } from "@/api/index";
import { queryBrowseByPage, plusBrowseNum } from "@/api/index";
import { eventBus } from '@/components/header/index';
import NewPager from "@/components/pagination/index";
import S from "./list.module.scss";
Vue.component("main_pager", NewPager);
@Component
class list extends Vue {
    activeName: any = ''
    titName: any = ''
    messageType: any = ''
    serch: any = ''
    total = 0
    pageSizes = 10
    page: any = {
        currentPage: 1,
        pageSize: 10
    }
    messageName: any = ''
    childrenMessageName: any = ''
    newsList: any = [
    ]
    menuList: any = [
        {
            menuName: "首页",
            id: 1,
            menuUrl: "/index",
            messageType: 'sy',
            value: "/index",
            show: false,
        },
        {
            menuName: "关于协会",
            menuUrl: "1",
            id: 2,
            show: true,
            childList: [
                {
                    menuName: "协会简介",
                    menuUrl: "list",
                    id: 3,
                    messageType: 'xhjj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会会员",
                    menuUrl: "list",
                    id: 4,
                    messageType: 'xhhy',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会章程",
                    menuUrl: "list",
                    id: 5,
                    messageType: 'xhzc',
                    show: true,
                    childList: []
                },
                {
                    menuName: "入会须知",
                    id: 6,
                    menuUrl: "list",
                    messageType: 'rhxz',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "关于学会",
            menuUrl: "2",
            id: 7,
            show: true,
            childList: [
                {
                    menuName: "学会简介",
                    menuUrl: "list",
                    id: 8,
                    messageType: 'gyxh',
                    show: true,
                    childList: []
                },
                {
                    menuName: "学术研究",
                    menuUrl: "list",
                    id: 9,
                    messageType: 'xsyj',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "通知公告",
            menuUrl: "3",
            id: 10,
            show: true,
            childList: [
                {
                    menuName: "通知公告",
                    menuUrl: "list",
                    id: 11,
                    messageType: 'tzgg',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "新闻咨询",
            menuUrl: "4",
            id: 12,
            show: true,
            childList: [
                {
                    menuName: "行业资讯",
                    id: 13,
                    menuUrl: "list",
                    messageType: 'hyyw',
                    show: true,
                    childList: []
                },
                {
                    menuName: "金融宣教",
                    id: 14,
                    menuUrl: "list",
                    messageType: 'jrxj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "会员动态",
                    id: 15,
                    menuUrl: "list",
                    messageType: 'hydt',
                    show: true,
                    childList: []
                },
                {
                    menuName: "专题报道",
                    id: 16,
                    menuUrl: "list",
                    messageType: 'ztbd',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会动态",
                    id: 17,
                    menuUrl: "list",
                    messageType: 'xhdt',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "教育培训",
            id: 18,
            menuUrl: "5",
            show: true,
            childList: [
                {
                    menuName: "保险网络大学",
                    id: 19,
                    menuUrl: "list",
                    messageType: 'bxwldx',
                    show: true,
                    childList: []
                },
                {
                    menuName: "讲师组训人员培训",
                    id: 32,
                    menuUrl: "list",
                    messageType: 'jszxrypx',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "消费维权",
            id: 20,
            menuUrl: "6",
            show: true,
            childList: [
                {
                    menuName: "中华人民共和国消费者权益保护法",
                    id: 99,
                    menuUrl: "list",
                    messageType: 'xfzbhf',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解委员会简介",
                    id: 21,
                    menuUrl: "list",
                    messageType: 'tjwyhj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "消费维权",
                    id: 22,
                    menuUrl: "list",
                    messageType: 'xfwq',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解注意事项",
                    id: 23,
                    menuUrl: "list",
                    messageType: 'tjzysx',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解流程",
                    id: 24,
                    menuUrl: "list",
                    messageType: 'tjlc',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解申请",
                    id: 25,
                    menuUrl: "list",
                    messageType: 'tjsq',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "党建园地",
            id: 26,
            menuUrl: "7",
            show: true,
            childList: [
                {
                    menuName: "党建要闻",
                    id: 27,
                    menuUrl: "list",
                    messageType: 'djyw',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "清廉金融",
            id: 28,
            menuUrl: "8",
            show: true,
            childList: [
                {
                    menuName: "清廉金融",
                    id: 29,
                    menuUrl: "list",
                    messageType: 'qljr',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "<安徽保险>",
            id: 30,
            menuUrl: "9",
            show: true,
            childList: [
                {
                    menuName: "<安徽保险>",
                    id: 31,
                    menuUrl: "list",
                    messageType: 'ahbx',
                    show: true,
                    childList: []
                },
            ]
        },
    ]
    mounted() {
        eventBus.$on('search', search => {
            this.serch = search;
        });
        this.titName = this.$route.query.menuName;
        this.messageType = this.$route.query.messageType;
        localStorage.setItem('parentMenu', this.messageType)
        this.getList(this.page.currentPage, this.page.pageSize);
        this.query(this.page.currentPage, this.page.pageSize);

    }
    // methods: {
    // 将页面滚动条位置重置到顶部

    getList(currentPage: any, pageSize: any) {
        const this_ = this;
        this.$on('pageChange', (event) => {
            this_.page.currentPage = event.currentPage;
            this_.query(event.currentPage, event.pageSize);
        });
    }

    route(e) {
        if (e.externalLinkUrl) {
            window.open(e.externalLinkUrl, '_blank');
        } else {
            this.$router.push({
                path: 'detail',
                query: { 'id': e.id, 'messageType': this.messageType }
            });
            const params = {
                messageId: e.id
            };
            plusBrowseNum(params).then((res: any) => {
            }).catch((err: any) => {
            });
        }
    }

    query(page, rows) {
        const params = {
            title: this.serch,
            messageType: this.messageType,
            pageIndex: page,
            pageSize: rows
        }
        queryBrowseByPage(params).then((res: any) => {
            this.newsList = res.data.value.records,
                this.total = res.data.value.totalCount
        }).catch((err: any) => {
        })
    }

    render() {
        return (
            <div class={S.wrapper}>
                <div class={S.row}>
                    <div class={S.t}>
                        <h1>{this.titName}</h1>
                    </div>
                    <div class={S.newsTabs}>
                        <el-tabs v-model={this.activeName}>
                            <ul class={S.newsList}>
                                {this.newsList.map((item: any, index: any) => (
                                    <li on-click={() => this.route(item)} >
                                        <span class={S.name}>{item.title}</span>
                                        <span class={S.time}>{item.releaseTime}</span>
                                    </li>
                                ))}
                            </ul>
                        </el-tabs>
                        <main_pager current-page={this.page.currentPage} page-sizes={this.pageSizes} pageSize={this.page.pageSize} total={this.total}></main_pager>
                    </div>
                </div>
            </div>
        );
    }
}

export default list;
