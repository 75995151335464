import { Vue, Component } from "vue-property-decorator";
import { queryBrowseByPage } from "@/api/index";
import S from "./index.module.scss"
export const eventBus = new Vue();
@Component
class Header extends Vue {
	search: any = ''
	titName: any = ''
	messageType: any = ''
	mounted() {

	}
	query() {
		if (this.search == "") {
			alert("请输入搜索内容")
			return
		}
		this.$router.push({
			path: 'search', query: { 'search_content': this.search }
		})
	}
	toHy() {
		// window.open('https://www.cisc.cn/AHIAWEB/LEAP/Login/34/IAWEB/Login.html')
		window.open('https://www.iaanhui.cn/website/')
	}
	isMobile(){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
  }
	render() {
		return (
			<div>
				<div class={S.header}>
					{!this.isMobile() &&(
					<div class={S.wrapper}>
						<div class={S.headerLogo}>
							<img class={S.img1} src={require('@/assets/img/title1.png')} alt="" />
							<img class={S.img2} src={require('@/assets/img/title2.png')} alt="" />
						</div>
						<div class={S.headerRight}>
							<div class={S.headTools}>
								<div class={S.item}>
									<i class="el-icon-s-home"></i>
									<span>设置首页</span>
								</div>
								<div class={S.item}>
									<i class="el-icon-star-on"></i>
									<span>加入收藏</span>
								</div>
								<div class={S.item} on-click={() => this.toHy()}>
									<i class="el-icon-star-on"></i>
									<span>会员登录</span>
								</div>
							</div>
							<div class={S.search}>
								<input class={S.input} type="text" v-model={this.search} placeholder="请您输入要搜索的关键字"></input>
								<div class={S.button} on-click={() => this.query()}></div>
							</div>
						</div>
					</div>
					)}
					{this.isMobile() &&(
					<div class={S.mWrapper}>
						<div class={S.headerLogo}>
							<img class={S.img1} src={require('@/assets/img/title1.png')} alt="" />
							<img class={S.img2} src={require('@/assets/img/title2.png')} alt="" />
						</div>
					</div>
					)}
				</div>
			</div>
		);
	}
}

export default Header;
