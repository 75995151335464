import { Vue, Component } from "vue-property-decorator";
// import { dangerouslySetInnerHTML } from 'react';
import { } from "@/api/index";
import { queryById } from "@/api/index";
// import ReactHtmlParser from 'react-html-parser';
import S from "./detail.module.scss";
@Component
class detail extends Vue {
    activeName: any = ''
    id: any = ''
    titName: any = ''
    issueTime: any = ''
    messageName: any = ''
    childrenMessageName: any = ''
    brief: any = ''
    sp: any = ''
    a: any = ''
    messageType: any = ''
    fileList: any = []
    origin: any = ''
    content: any = ''
    edit: any = false
    newsList: any = [
    ]
    menuList: any = [
        {
            menuName: "首页",
            id: 1,
            menuUrl: "index",
            messageType: 'sy',
            value: "index",
            show: false,
            childList: [
                {
                    menuName: "视频集锦",
                    menuUrl: "list",
                    id: 35,
                    messageType: 'spjj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "图片新闻",
                    menuUrl: "list",
                    id: 35,
                    messageType: 'tpxw',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "关于协会",
            menuUrl: "1",
            id: 2,
            show: true,
            childList: [
                {
                    menuName: "协会简介",
                    menuUrl: "list",
                    id: 3,
                    messageType: 'xhjj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会会员",
                    menuUrl: "list",
                    id: 4,
                    messageType: 'xhhy',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会章程",
                    menuUrl: "list",
                    id: 5,
                    messageType: 'xhzc',
                    show: true,
                    childList: []
                },
                {
                    menuName: "入会须知",
                    id: 6,
                    menuUrl: "list",
                    messageType: 'rhxz',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "关于学会",
            menuUrl: "2",
            id: 7,
            show: true,
            childList: [
                {
                    menuName: "学会简介",
                    menuUrl: "list",
                    id: 8,
                    messageType: 'gyxh',
                    show: true,
                    childList: []
                },
                {
                    menuName: "学术研究",
                    menuUrl: "list",
                    id: 9,
                    messageType: 'xsyj',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "通知公告",
            menuUrl: "3",
            id: 10,
            show: true,
            childList: [
                {
                    menuName: "通知公告",
                    menuUrl: "list",
                    id: 11,
                    messageType: 'tzgg',
                    show: true,
                    childList: []
                },
                {
                    menuName: "通知公告",
                    menuUrl: "list",
                    id: 11,
                    messageType: 'gdgg',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "新闻资讯",
            menuUrl: "4",
            id: 12,
            show: true,
            childList: [
                {
                    menuName: "行业资讯",
                    id: 13,
                    menuUrl: "list",
                    messageType: 'hyyw',
                    show: true,
                    childList: []
                },
                {
                    menuName: "金融宣教",
                    id: 14,
                    menuUrl: "list",
                    messageType: 'jrxj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "会员动态",
                    id: 15,
                    menuUrl: "list",
                    messageType: 'hydt',
                    show: true,
                    childList: []
                },
                {
                    menuName: "专题报道",
                    id: 16,
                    menuUrl: "list",
                    messageType: 'ztbd',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会动态",
                    id: 17,
                    menuUrl: "list",
                    messageType: 'xhdt',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "教育培训",
            id: 18,
            menuUrl: "5",
            show: true,
            childList: [
                {
                    menuName: "保险网络大学",
                    id: 19,
                    menuUrl: "list",
                    messageType: 'bxwldx',
                    show: true,
                    childList: []
                },
                {
                    menuName: "讲师组训人员培训",
                    id: 32,
                    menuUrl: "list",
                    messageType: 'jszxrypx',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "消费维权",
            id: 20,
            menuUrl: "6",
            show: true,
            childList: [
                {
                    menuName: "中华人民共和国消费者权益保护法",
                    id: 99,
                    menuUrl: "list",
                    messageType: 'xfzbhf',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解委员会简介",
                    id: 21,
                    menuUrl: "list",
                    messageType: 'tjwyhj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "消费维权",
                    id: 22,
                    menuUrl: "list",
                    messageType: 'xfwq',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解注意事项",
                    id: 23,
                    menuUrl: "list",
                    messageType: 'tjzysx',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解流程",
                    id: 24,
                    menuUrl: "list",
                    messageType: 'tjlc',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解申请",
                    id: 25,
                    menuUrl: "list",
                    messageType: 'tjsq',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "党建园地",
            id: 26,
            menuUrl: "7",
            show: true,
            childList: [
                {
                    menuName: "党建园地",
                    id: 27,
                    menuUrl: "list",
                    messageType: 'djyw',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "清廉金融",
            id: 28,
            menuUrl: "8",
            show: true,
            childList: [
                {
                    menuName: "清廉金融",
                    id: 29,
                    menuUrl: "list",
                    messageType: 'qljr',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "安徽保险",
            id: 30,
            menuUrl: "9",
            show: true,
            childList: [
                {
                    menuName: "安徽保险",
                    id: 31,
                    menuUrl: "list",
                    messageType: 'ahbx',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "搜索结果",
            id: 30,
            menuUrl: "9",
            show: true,
            childList: [
                {
                    menuName: "搜索结果",
                    id: 31,
                    menuUrl: "list",
                    messageType: 'ssjg',
                    show: true,
                    childList: []
                },
            ]
        },
    ]
    video: any = ""
    mounted() {
        this.id = this.$route.query.id;
        this.sp = this.$route.query.menuName;
        this.a = localStorage.getItem('parentMenu')
        this.messageName = this.menuList.find(item => item.childList && item.childList.length > 0 && item.childList.some(childItem => childItem.messageType === this.a))?.menuName;
        this.childrenMessageName = '';
        this.menuList.forEach(item => {
            if (item.childList && item.childList.length > 0) {
                const childItem = item.childList.find(child => child.messageType === this.a);
                if (childItem) {
                    this.childrenMessageName = childItem.menuName;
                }
            }
        });
        this.query()
    }


    query() {
        queryById(this.id).then((res: any) => {
            console.log(res.data.value);
            this.video = res.data.value.videoHttp
            this.titName = res.data.value.title
            this.issueTime = res.data.value.releaseTime
            this.brief = res.data.value.brief
            this.origin = res.data.value.origin
            this.content = res.data.value.content.replace(/<[^/>]*>([\s]*?|&nbsp;)*<\/[^>]*>/g, '')
            this.messageType = res.data.value.messageType
            this.fileList = res.data.value.fileTempList
        }).catch((err: any) => {
        })
    }


    ret() {
        this.$router.push({ path: 'index' });
        localStorage.setItem("titName", this.messageName);

        let messageType = '';

        const menu = this.menuList.find(item => item.menuName === this.messageName);
        if (menu) {
            const menuChildren = menu.childList.find(child => child.menuName === this.childrenMessageName);
            if (menuChildren) {
                messageType = menuChildren.messageType;
            }
        }
        if (messageType) {
            this.$router.push({
                path: 'list',
                query: {
                    'messageType': messageType,
                    'menuName': this.childrenMessageName,
                    'parentMessageType': this.messageType
                }
            });
        }
    }

    upload(item) {
        const downloadUrl = item.url;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = item.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <div class={S.wrapper} ref='scrollContainerRef'>
                <div class={S.row}>
                    <div class={S.nav}>
                        <a class={S.span1} on-click={() => this.ret()} >{this.messageName}</a>
                        <span class={S.b}>|</span>
                        <span>{this.childrenMessageName}</span>
                    </div>
                    <div class={S.t}>
                        <h1>{this.titName}</h1>
                    </div>
                    <div class={S.h}>
                        <span>{this.brief}</span>
                    </div>
                    <div>
                        <ul class={S.newsList}>
                            <li>
                                <span class={S.name}>发布时间：{this.issueTime}</span>
                                <span class={S.time}>来源：{this.origin}</span>
                            </li>
                        </ul>
                    </div>
                    {this.video == "" && (
                        <div class={S.content} domPropsInnerHTML={this.content} ></div>
                    )}
                    {this.video != "" && (
                        <div style="width:700px;margin:0 auto;">
                            <video src={this.video} controls muted loop width="700" height="400" />
                        </div>
                    )}
                    {this.fileList.length > 0 && (
                        <ul style='margin-left:100px;margin-bottom:50px'>
                            <span>
                                附件:
                            </span>
                            {this.fileList.map((item: any) => (
                                <div class={S.em} on-click={() => this.upload(item)}>
                                    {item.name}
                                </div>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
}

export default detail;
