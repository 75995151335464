import { Vue, Component } from "vue-property-decorator";
import { getHomePage, plusBrowseNum } from "@/api/index";
import Swiper from 'swiper';
import S from "./index.module.scss";
@Component
class index extends Vue {
  newsCarousel: any = []
  activeName: any = "first"
  activeVideo: any = "first"
  activeSwiper: any = "first"
  newsList: any = []
  Home: any = {
    journalism: [],
    industryNews: [],
    associationDynamics: [],
    link: [],
    dynamic: [],
    financialEducation: [],
    inform: [],
    advertising: [],
    life: [],
    property: [],
    intermediary: [],
    videoLink: []
  }
  name: any = {
    name: "first"
  }
  swiper: any = []
  mounted() {
    // new Swiper(".swiperStyle", {
    //   loop: true,
    //   // 如果需要分页器
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //     renderBullet: function (index, className) {
    //       return '<span class="' + className + '">' + (index + 1) + "</span>";
    //     }
    //   }
    // })
    // new Swiper(".swiperLogo2", {
    //   slidesPerView: 5,
    //   spaceBetween: 30,
    //   loop: true,
    //   observer: true,
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   }
    // })
    new Swiper(".swiperLogo3", {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: true,
      observer: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
    this.getHomePage()
    this.plusBrowseNum("")
  }
  plusBrowseNum(e) {
    const params = {
      messageId: e
    }
    plusBrowseNum(params).then((res: any) => {

    }).catch((err: any) => {

    })
  }
  getHomePage() {
    getHomePage().then((res: any) => {
      this.Home = res.data.value
    }).catch((err: any) => {

    })
  }
  toList(e) {
    if (e.externalLinkUrl.indexOf("http") != -1) {
      window.open(e.externalLinkUrl)
    } else {
      if (e.name == "通知公告") {
        this.plusBrowseNum(e.messageType)
        this.$router.push({
          path: e.externalLinkUrl, query: { 'messageType': e.messageType, 'id': e.id, }
        })
      } else {
        this.plusBrowseNum(e.messageType)
        this.$router.push({
          path: e.externalLinkUrl, query: { 'messageType': e.messageType, 'menuName': e.name }
        })


      }
    }
  }
  toDetails(e, d) {
    console.log(e);
    if (e.externalLinkUrl == null) {
      e.externalLinkUrl = "11"
    }
    if (e.externalLinkUrl.indexOf("http") != -1) {
      window.open(e.externalLinkUrl)
    } else if (e.externalLinkUrl == null || e.externalLinkUrl !== "") {
      this.plusBrowseNum(e.id)
      localStorage.removeItem('parentMenu')
      localStorage.setItem("parentMenu", d);
      this.$router.push({
        path: 'detail', query: { 'menuName': e.title, 'id': e.id }
      })
    }
  }
  more(e) {
    if (e == "1") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'cxhygs', 'menuName': "财产险会员公司" }
      })
    }
    else if (e == "8") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'hyyw', 'menuName': "行业资讯" }
      })
    }
    else if (e == "2") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'bxzjhygs', 'menuName': "保险中介会员公司" }
      })
    } else if (e == "5") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'sxhygs', 'menuName': "人身险会员公司" }
      })
    } else if (e == "3") {
      if (this.activeSwiper == "first") {
        this.$router.push({
          path: '/resList', query: { 'messageType': 'cxhygs', 'menuName': "财产险会员公司" }
        })
      } else if (this.activeSwiper == "second") {
        this.$router.push({
          path: '/resList', query: { 'messageType': 'sxhygs', 'menuName': "人身险会员公司" }
        })
      }
    } else if (e == "4") {
      this.$router.push({
        path: '/resList', query: { 'messageType': 'spjj', 'menuName': "视频集锦" }
      })
    } else if (e == "10") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'xhdt', 'menuName': "协会动态" }
      })
    } else if (e == "11") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'hydt', 'menuName': "会员动态" }
      })
    } else if (e == "12") {
      this.$router.push({
        path: '/list', query: { 'messageType': 'jrxj', 'menuName': "金融宣教" }
      })
    }
  }
  toTZGG() {
    localStorage.setItem('parentMenu', 'tzgg')
    this.$router.push({
      path: '/detail',
      query: { 'id': '230805506', 'messageType': 'tzgg' }
    })
  }
  render() {
    return (
      <div class={S.wrapper}>
        <div class={S.row}>
          <div class={S.newsCarousel}>
            <el-carousel height="358px" direction="vertical">
              {this.Home.journalism.map((item: any, index: any) => (
                <el-carousel-item key={index}>
                  <div class={S.item} on-click={() => this.toDetails(item, 'tpxw')}>
                    <img src={item.coverPicHttp} alt="" />
                    <span class={S.newsDate}>
                      <b class={S.up}>{index + 1}</b>
                      <b class={S.down}>{this.Home.journalism.length}</b>
                    </span>
                    <p class={S.itemTitle}>{item.title}</p>
                  </div>
                </el-carousel-item>
              ))}
            </el-carousel>
          </div>
          <div class={S.newsTabs}>
            <div class={S.blockLeft}>
              <div class={S.title}>
                <span class={S.text}>行业资讯</span>
                <span class={S.more} on-click={() => this.more('8')}>更多</span>
              </div>
              <ul class={S.newsList}>
                {this.Home.industryNews.map((item: any, index: any) => (
                  <li on-click={() => this.toDetails(item, 'hyyw')}>
                    <span class={S.name}>{item.title}</span>
                    <span class={S.time}>{item.releaseDate}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* <el-tabs v-model={this.activeName}>
              <el-tab-pane label="行业资讯" name="first">
                <ul class={S.newsList} >
                  {this.Home.industryNews.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'hyyw')}>
                      <span class={S.name}>{item.title}</span>
                      <span class={S.time}>{item.releaseDate}</span>
                    </li>
                  ))}
                </ul>
              </el-tab-pane> */}
            {/*<el-tab-pane label="协会动态" name="second">
                <ul class={S.newsList}>
                  {this.Home.associationDynamics.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item)}>
                      <span class={S.name}>{item.title}</span>
                      <span class={S.time}>{item.releaseDate}</span>
                    </li>
                  ))}
                </ul>
              </el-tab-pane>
              <el-tab-pane label="通知公告" name="third">
                <ul class={S.newsList}>
                  {this.Home.inform.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item)}>
                      <span class={S.name}>{item.title}</span>
                      <span class={S.time}>{item.releaseDate}</span>
                    </li>
                  ))}
                </ul>
              </el-tab-pane>*/}
            {/* </el-tabs> */}
          </div>
          <div class={S.clear}></div>
        </div>
        <div class={S.row}>
          <div class={S.textScrollWrap}>
            <span class={S.name}>通知公告</span>
            <div class={S.textScrollInner}>
              <ul>
                {this.Home.inform.map((item: any, index: any) => (
                  <li on-click={() => this.toDetails(item, 'tzgg')}>{item.title}<span>{item.releaseDate}</span></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div class={S.ac_row}>
          <el-row gutter={20}>
            <el-col span={8}>
              <div class={S.blockLeft}>
                <div class={S.title}>
                  <span class={S.text}>协会动态</span>
                  <span class={S.more} on-click={() => this.more('10')}>更多</span>
                </div>
                <ul class={S.newsList}>
                  {this.Home.associationDynamics.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'xhdt')}>
                      <span class={S.name}>{item.title}</span>
                      <span class={S.time}>{item.releaseDate}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </el-col>
            <el-col span={8}>
              <div class={S.blockLeft}>
                <div class={S.title}>
                  <span class={S.text}>会员动态</span>
                  <span class={S.more} on-click={() => this.more('11')}>更多</span>
                </div>
                <ul class={S.newsList}>
                  {this.Home.dynamic.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'hydt')}>
                      <span class={S.name}>{item.title}</span>
                      <span class={S.time}>{item.releaseDate}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </el-col>
            <el-col span={8}>
              <div class={S.blockLeft}>
                <div class={S.title}>
                  <span class={S.text}>金融宣教</span>
                  <span class={S.more} on-click={() => this.more('12')}>更多</span>
                </div>
                <ul class={S.newsList}>
                  {this.Home.financialEducation.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'jrxj')}>
                      <span class={S.name}>{item.title}</span>
                      <span class={S.time}>{item.releaseDate}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        {/*<div class={S.row}>
          <div style="">
            <div class="swiperStyle">
              <div class="swiper-wrapper">
                {this.Home.advertising.map((item: any, index: any) => (
                  <div class="swiper-slide" on-click={() => this.toDetails(item)}>
                    <img src={item.coverPicUrl} alt="" />
                  </div>
                ))}
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>*/}
        {/*<div class={S.swiperRow}>
          <div class={S.swiperTabs}>
            <span class={S.more} on-click={() => this.more('3')}>更多</span>
            <el-tabs class={S.swiperTable} v-model={this.activeSwiper} on-tab-click={(tab) => this.handleClick(tab)}>
              <el-tab-pane label="财险会员公司" name="first">
              </el-tab-pane>
              <el-tab-pane label="寿险会员公司" name="second">
              </el-tab-pane>
            </el-tabs>
            <div style="">
              <div class="swiperLogo2">
                <div class="swiper-wrapper">
                  {this.swiper.map((item: any, index: any) => (
                    <div class="swiper-slide" on-click={() => this.toDetails(item)}>
                      <img class="logoImg" src={item.coverPicUrl} alt="" />
                    </div>
                  ))}
                </div>
                <div class="swiper-button-prev top65"></div>
                <div class="swiper-button-next top65"></div>
              </div>
            </div>
          </div>
        </div>*/}
        <div class={S.swiperRow}>
          <div class={S.swiperTabs2}>
            <div class={S.title}>
              <img src={require('../../assets/img/vl.png')} alt="" />
              <span>视频集锦</span>
              <img src={require('../../assets/img/vr.png')} alt="" />
            </div>
            <span class={S.more} on-click={() => this.more('4')}>更多</span>
            <div style="margin:0 25px;">
              <div class="swiperLogo3">
                <div class="swiper-wrapper">
                  {this.Home.videoLink.map((item: any, index: any) => (
                    <div class="swiper-slide" on-click={() => this.toDetails(item, 'spjj')}>
                      <img class="logoImg" src={item.coverPicHttp} alt="" />
                      <p style="text-align:center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{item.title}</p >
                    </div>
                  ))}
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div class={S.ac2_row}>
          <el-row gutter={20}>
            <el-col span={8}>
              <div class={S.blockLeft}>
                <div class={S.title}>
                  <span class={S.text}>财产险会员公司</span>
                  <span class={S.more} on-click={() => this.more('1')}>更多</span>
                </div>
                <ul class={S.newsList}>
                  {this.Home.property.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'cxhygs')}>
                      <span class={S.name}>{item.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </el-col>
            <el-col span={8}>
              <div class={S.blockLeft}>
                <div class={S.title}>
                  <span class={S.text}>人身险会员公司</span>
                  <span class={S.more} on-click={() => this.more('5')}>更多</span>
                </div>
                <ul class={S.newsList}>
                  {this.Home.life.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'sxhygs')}>
                      <span class={S.name}>{item.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </el-col>
            <el-col span={8}>
              <div class={S.blockLeft}>
                <div class={S.title}>
                  <span class={S.text}>保险中介会员公司</span>
                  <span class={S.more} on-click={() => this.more('2')}>更多</span>
                </div>
                <ul class={S.newsList}>
                  {this.Home.intermediary.map((item: any, index: any) => (
                    <li on-click={() => this.toDetails(item, 'bxzjhygs')}>
                      <span class={S.name}>{item.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class={S.row} style="background-color: #fff;margin-bottom:20px;">
          <div class={S.phone}>
            <div class={S.title}>
              <img src={require('../../assets/img/vl.png')} alt="" />
              <span>金融消费者服务热线</span>
              <img src={require('../../assets/img/vr.png')} alt="" />
            </div>
            <ul>
              <li>
                <img src={require('../../assets/img/dianhua1.png')} alt="" />
                <div class={S.telphone}>
                  <p class={S.name}>国家金融监督管理总局<br />消费者投诉电话</p>
                  <p class={S.num}>12378</p>
                </div>
              </li>
              <li style="text-align: center;">
                <img src={require('../../assets/img/dianhua2.png')} alt="" />
                <div class={S.telphone}>
                  <p class={S.name}>安徽银行业保险业金融纠紛人民调解委员会消费者维权服务热线</p>
                  <p class={S.num}>0551-65395301</p>
                </div>
              </li>
              <li style="text-align: right;">
                <img src={require('../../assets/img/kefu.png')} alt="" />
                <div class={S.telphone}>
                  <p class={S.name}>安徽省保险消费者维权<br />服务热线</p>
                  <p class={S.click} on-click={() => this.toTZGG()}>请点击查看电话号码</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
