import service from "./service";
//列表查询
export function queryBrowseByPage(data: any) {
  return service({
    url: `/official-website-main/browse/queryBrowseByPage`,
    method: "post",
    data
  });
} 

// 详情查询
export function queryById(id) {
  return service({
    url: `/official-website-main/browse/queryById`,
    method: "get",
    params: {id},
  });
} 
// 首页查询
export function getHomePage() {
  return service({
    url: `/official-website-main/browse/getHomePage`,
    method: "post"
  });
} 


//浏览量新增
export function plusBrowseNum(data:any) {
  return service({
    url: `/official-website-main/browse/plusBrowseNum`,
    method: "post",
    data
  });
} 

//浏览量查询
export function getBrowseInfo() {
  return service({
    url: `/official-website-main/browse/getBrowseInfo`,
    method: "post"
  });
} 


// // 搜索
// export function queryBrowseByPage() {
//   return service({
//     url: `/official-website-main/browse/queryBrowseByPage`,
//     method: "post"
//   });
// }
//获取调解模板路径
export function getMediationTemplatePath() {
  return service({
    url: `/official-website-main/browse/getMediationTemplatePath`,
    method: "get"
  });
} 


//调解新增
export function mediationCommit(data:any) {
  return service({
    url: `/official-website-main/browse/mediationCommit`,
    method: "post",
    data
  });
} 

// 附件上传
export function uploadMediationFile(data:any) {
  return service({
    url: `/official-website-main/browse/uploadMediationFile`,
    method: "post",
    data
  });
} 























