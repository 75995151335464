import { Vue, Component } from "vue-property-decorator";

import S from "./home.module.scss"
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import main_menu from '@/components/menu/index'
import Breadcrumb from '@/components/breadcrumb/index'
Vue.component("main_head", Header);
Vue.component("main_footer", Footer);
Vue.component("main_menu", main_menu);
Vue.component("main_breadcrumb", Breadcrumb);
@Component
class Home extends Vue {
  mounted() {
    const scrollContainerRef: any = this.$refs.scoll
    scrollContainerRef.scrollTop = 0;
  }
  render() {
    return (
      <div class={S.wrapper}>
        <div class={S.container}>
          <main_head></main_head>
          <main_menu></main_menu>
          <div class={S.content} ref='scoll'>
            <router-view></router-view>
          </div>
          <main_footer></main_footer>
        </div>
      </div>
    );
  }
}

export default Home;
