import { Vue, Component, Provide } from "vue-property-decorator";
import "./app.scss";
@Component
class App extends Vue {
  name = "app";
  @Provide('reload1')
  reload1 = this.reload;
  isRouterAlive = true
  reload(): void {
    this.isRouterAlive = false
    this.$nextTick(function () {
      this.isRouterAlive = true
    })
  }
  render() {
    return (
      <div id="app">
        {
          this.isRouterAlive
            ? <router-view />
            : ''
        }
      </div>
    );
  }
}
export default App;
