import { Vue, Component } from "vue-property-decorator";

import S from "./home.module.scss"
@Component
class Home extends Vue {


  mounted() {
    if( this.isMobile() ){
      console.log("手机端")
      this.$router.push({
        path: 'mHome',
      });
      
    }else{
      console.log("PC端")
      this.$router.push({
        path: 'home',
      });
    }
  }
  isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
  }
  render() {
    return (
      <div class={S.wrapper}>
        
      </div>
    );
  }
}

export default Home;
