import { Vue, Component } from "vue-property-decorator";
import S from "./list.module.scss"
import NewPager from "@/components/pagination/index";
import { eventBus } from '@/components/header/index';
import { getMediationTemplatePath, mediationCommit, uploadMediationFile } from "@/api/index";
Vue.component("main_pager", NewPager);
@Component
class complaint extends Vue {
    titName: any = ''
    messageType: any = ''
    serch: any = ''
    formInline: any = {}
    fileList = []
    fileUuid: any = []
    identityType: any = [
        {
            id: '1',
            name: '投保人'
        },
        {
            id: '2',
            name: '被保险人'
        },
        {
            id: '3',
            name: '受益人'
        },
        {
            id: '4',
            name: '代理人'
        },
        {
            id: '5',
            name: '其他'
        },
    ]
    companyType: any = [
        {
            id: '1',
            name: '财产险'
        },
        {
            id: '2',
            name: '人身险'
        },
        {
            id: '3',
            name: '中介机构'
        },
    ]
    cityArea: any = [
        {
            id: '1',
            name: '合肥'
        },
        {
            id: '2',
            name: '芜湖'
        },
        {
            id: '3',
            name: '蚌埠'
        },
        {
            id: '4',
            name: '淮南'
        },
        {
            id: '5',
            name: '阜阳'
        },
        {
            id: '6',
            name: '淮北'
        },
        {
            id: '7',
            name: '滁州'
        },
        {
            id: '8',
            name: '六安'
        },
        {
            id: '9',
            name: '安庆'
        },
        {
            id: '10',
            name: '宣城'
        },
        {
            id: '11',
            name: '宿州'
        },
        {
            id: '12',
            name: '亳州'
        },
        {
            id: '13',
            name: '池州'
        },
        {
            id: '14',
            name: '马鞍山'
        },
        {
            id: '15',
            name: '铜陵'
        },
        {
            id: '16',
            name: '黄山'
        },
        {
            id: '17',
            name: '省外（网络及电销等）'
        },
    ]
    purchasingChannels: any = [
        {
            id: '1',
            name: '营销员'
        },
        {
            id: '2',
            name: '保险公司柜面'
        },
        {
            id: '3',
            name: '银行邮政渠道'
        },
        {
            id: '4',
            name: '电话销售'
        },
        {
            id: '5',
            name: '网络销售'
        },
        {
            id: '6',
            name: '单位（学校）集体购买'
        },
        {
            id: '7',
            name: '4S店销售（车险）'
        },
    ]
    isComplaintOther: any = [
        {
            id: '1',
            name: '是'
        },
        {
            id: '2',
            name: '否'
        }
    ]
    mounted() {
        eventBus.$on('search', search => {
            this.serch = search;
        });
        this.titName = this.$route.query.menuName;
        this.messageType = this.$route.query.messageType;
    }

    uploadFile(file) {
        let form: any = new FormData()
        form.append('file', file.file);
        uploadMediationFile(form).then((res: any) => {
            this.fileUuid.push(res.data.value[0].fileUuid)
        }).catch((err: any) => {
        })
    }

    submit() {
        const params = {
            identityNum: this.formInline.identityNum,
            name: this.formInline.name,
            phoneNum: this.formInline.phone,
            identityType: Number(this.formInline.identityType),
            companyType: Number(this.formInline.companyType),
            companyName: this.formInline.companyName,
            cityArea: this.formInline.cityArea,
            purchasingChannels: Number(this.formInline.purchasingChannels),
            insuranceName: this.formInline.insuranceName,
            policyNum: this.formInline.policyNum,
            insuranceTime: this.formInline.insuranceTime,
            problemOccurrenceTime: this.formInline.problemOccurrenceTime,
            isComplaintOther: Number(this.formInline.isComplaintOther),
            address: this.formInline.address,
            licensePlate: this.formInline.licensePlate,
            emailNum: this.formInline.email,
            sexy: this.formInline.sex,
            content: this.formInline.leave,
            fileUuids: this.fileUuid,
            otherComplaint: this.formInline.complaintChannel
        }
        mediationCommit(params).then((res: any) => {
            if (this.formInline.isComplaintOther == 1 && !this.formInline.complaintChannel) {
                alert('请填写投诉渠道名称')
            }
            else if (!this.formInline.isComplaintOther) {
                alert('请填写是否已在其他渠道投诉')
            }
            else {
                alert(res.data.message)
            }
            if (res.data.code == 200) {
                this.formInline = {}
                this.fileList = []
                this.fileUuid = []
            }
        }).catch((err: any) => {
        })
    }

    download() {
        getMediationTemplatePath().then((res: any) => {
            window.open(res.data.value)
        }).catch((err: any) => {
            console.error('下载失败：', err);
        });
    }

    render() {
        return (
            <div class={S.wrapper}>
                <div class={S.row}>
                    <div class={S.t}>
                        <h1>{this.titName}</h1>
                    </div>
                    <div class={S.newsTab}>
                        <el-form inline={true} props={{ model: this.formInline }}>
                            <div class={S.left}>
                                <el-form-item label='投诉人姓名'>
                                    <el-input v-model={this.formInline.name} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='投诉人身份证号'>
                                    <el-input v-model={this.formInline.identityNum} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='投诉公司类型'>
                                    <el-select v-model={this.formInline.companyType} clearable>
                                        {this.companyType.map((item: any) => (
                                            <el-option label={item.name} value={item.id}></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='所在城市'>
                                    <el-select v-model={this.formInline.cityArea} clearable>
                                        {this.cityArea.map((item: any) => (
                                            <el-option label={item.name} value={item.name}></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='所购保险产品名称'>
                                    <el-input v-model={this.formInline.insuranceName} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                                <el-form-item label='投保时间' class={S.tim}>
                                    <el-date-picker
                                        v-model={this.formInline.insuranceTime}
                                        type="date"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        placeholder="请选择">
                                    </el-date-picker>
                                </el-form-item>

                                <el-form-item label='是否已在其他渠道投诉'>
                                    <el-select v-model={this.formInline.isComplaintOther} clearable>
                                        {this.isComplaintOther.map((item: any) => (
                                            <el-option label={item.name} value={item.id}></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='车牌号码'>
                                    <el-input v-model={this.formInline.licensePlate} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                            </div>
                            <div class={S.right}>
                                <el-form-item label='投诉人手机号'>
                                    <el-input v-model={this.formInline.phone} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='投诉人身份'>
                                    <el-select v-model={this.formInline.identityType} clearable>
                                        {this.identityType.map((item: any) => (
                                            <el-option label={item.name} value={item.id}></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                                {this.formInline.identityType == 4 && (
                                    <div class={S.up}>请上传授权书!</div>
                                )}
                                <el-form-item label='投诉保险机构名称'>
                                    <el-input v-model={this.formInline.companyName} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                                {/* <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='购买渠道'>
                                    <el-select v-model={this.formInline.purchasingChannels} clearable>
                                        {this.purchasingChannels.map((item: any) => (
                                            <el-option label={item.name} value={item.id}></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item> */}
                                <lable style="color:red;" class={S.must}>*</lable>
                                <el-form-item label='保单号码' >
                                    <el-input v-model={this.formInline.policyNum} placeholder={'请输入'} clearable></el-input>
                                </el-form-item>
                                <el-form-item label='问题发生时间' class={S.tim} >
                                    <el-date-picker
                                        v-model={this.formInline.problemOccurrenceTime}
                                        type="date"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        placeholder="请选择">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label='通讯地址' >
                                    <el-input v-model={this.formInline.address} placeholder={'请输入'} clearable ></el-input>
                                </el-form-item>
                                {this.formInline.isComplaintOther == 1 && (
                                    <div>
                                        <el-form-item label='投诉渠道名称' >
                                            <el-input v-model={this.formInline.complaintChannel} placeholder={'请输入'} clearable ></el-input>
                                        </el-form-item>
                                        <lable style="color:red;" class={S.must}>*</lable>
                                    </div>
                                )}
                            </div>
                            <div class={S.clear}></div>
                            <div style="width:100%;display:flex;">
                                <el-form-item style="flex:1;display:flex;" label="投诉事由及诉求" >
                                    <el-input style="width:100%;" type="textarea" rows='6' v-model={this.formInline.leave}></el-input>
                                </el-form-item>
                                <lable style="color:red;" class={S.must}>*</lable>
                            </div>
                            <el-upload
                                class="upload-demo"
                                action="555"
                                on-change="handleChange"
                                http-request={this.uploadFile}
                                file-list={this.fileList}>
                                <el-button style='' size="small" type="primary">点击上传附件</el-button>
                                <div slot="tip" class="el-upload__tip"></div>
                            </el-upload>
                            {/* <div class={S.mar}>
                                附件下载：
                                <span class={S.dow} on-click={() => this.download()}>安徽保险消费者投诉申请</span>
                            </div> */}
                            <div class={S.but}>
                                <el-button type="danger" on-click={() => this.submit()}>提交</el-button>
                            </div>
                        </el-form >
                    </div>
                    <div class={S.newsTabs}>
                        <span class={S.sp} >
                            投诉须知
                        </span>
                        <div class={S.detail}>
                            &nbsp;&nbsp; 1.本渠道受理的保险消费者投诉，是指投诉人认为保险机构及其从业人员在保险经营服务中侵害其合法权益，提出的保险消费投诉事项。<br />
                            &nbsp;&nbsp; 2.本渠道受理安徽省区域内保险公司承保的保险合同纠纷。<br />
                            &nbsp;&nbsp; 3.消费者本人提出消费投诉确有困难，可以委托他人代为投诉，但需提供委托人亲笔签名的授权委托书及双方有效身份证明和联系方式。<br />
                            &nbsp;&nbsp; 4.请自觉遵守国家相关法律法规，所提事项应当客观真实，不得提供虚假信息或者捏造、歪曲事实。<br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default complaint;