import { Vue, Component } from "vue-property-decorator";
// import { dangerouslySetInnerHTML } from 'react';
import { } from "@/api/index";
import { queryById, queryBrowseByPage, plusBrowseNum } from "@/api/index";

import S from "./search.module.scss";
@Component
class search extends Vue {
    activeName: any = ''
    id: any = ''
    titName: any = ''
    issueTime: any = ''
    sp: any = ''
    a: any = ''
    messageType: any = ''
    origin: any = ''
    searchContent: any = ''
    content: any = ''
    edit: any = false
    newsList: any = [
    ]
    total = 0
    pageSizes = 90
    page: any = {
        currentPage: 1,
        pageSize: 10
    }
    mounted() {
        this.id = this.$route.query.id;
        this.searchContent = this.$route.query.search_content;
        this.sp = this.$route.query.menuName;
        this.a = localStorage.getItem('parentMenu')
        this.getList(this.page.currentPage, this.page.pageSize);
        this.serch(this.page.currentPage, this.page.pageSize);
    }

    // getList(currentPage: any, pageSize: any) {
    //     const this_ = this;
    //     this.$on('pageChange', (event) => {
    //         this_.page.currentPage = event.currentPage;
    //         this_.serch(event.currentPage, event.pageSize);
    //     });
    // }
    getList(currentPage: any, pageSize: any) {
        const this_ = this;
        this.$on('pageChange', (event) => {
            this_.page.currentPage = event.currentPage;
            this_.serch(event.currentPage, event.pageSize);
        });
    }



    query() {
        queryById(this.id).then((res: any) => {
            // this.titName = res.data.value.title
            this.issueTime = res.data.value.releaseTime
            this.origin = res.data.value.origin
            this.content = res.data.value.content
            this.messageType = res.data.value.messageType
        }).catch((err: any) => {
        })
    }
    serch(page, rows) {
        const params = {
            title: this.searchContent,
            // messageType: this.a,
            pageIndex: page,
            pageSize: rows
        }
        queryBrowseByPage(params).then((res: any) => {
            this.newsList = res.data.value.records
            this.total = res.data.value.totalCount
        }).catch((err: any) => {
        })
    }

    route(e) {
        localStorage.setItem('parentMenu', 'ssjg')
        this.$router.push({
            path: 'detail',
            query: { 'id': e.id, }
        })
        const params = {
            messageId: e.id
        }
        plusBrowseNum(params).then((res: any) => {
        }).catch((err: any) => {
        })
    }

    render() {
        return (
            <div class={S.wrapper}>
                <div class={S.row}>
                    <div class={S.t}>
                        <h1>搜索结果</h1>
                    </div>
                    <div class={S.newsTabs}>
                        <el-tabs v-model={this.activeName}>
                            <ul class={S.newsList}>
                                {this.newsList.map((item: any, index: any) => (
                                    <li on-click={() => this.route(item)} >
                                        <span class={S.name}>{item.title}</span>
                                        <span class={S.time}>{item.releaseTime}</span>
                                    </li>
                                ))}
                            </ul>
                        </el-tabs>
                        <main_pager current-page={this.page.currentPage} page-sizes={this.pageSizes} pageSize={this.page.pageSize} total={this.total}></main_pager>
                    </div>
                </div>
            </div>
        );
    }
}

export default search;
