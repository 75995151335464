import { Vue, Component } from "vue-property-decorator";

import S from "./index.module.scss"
@Component
class Breadcrumb extends Vue {

  mounted(){
    console.log(this.$store.state.menuName)
  }
  render() {
    return (
       <div class={S.wrapper}>
         <div class={S.row}>
         <div class={S.line}></div>
          <p>{this.$store.state.menuName}</p>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>{this.$store.state.menuNav[0]}</el-breadcrumb-item>
          </el-breadcrumb>
          </div>
       </div>
    );
  }
}

export default Breadcrumb;
