import { Vue, Component } from "vue-property-decorator";

import S from "./index.module.scss";
@Component
class Menu extends Vue {


  menuList: any = [
    {
      menuName: "首页",
      id: "1",
      menuUrl: "/index",
      messageType: 'sy',
      value: "/index",
      show: false,
    },
    {
      menuName: "关于协会",
      menuUrl: "1",
      id: "2",
      show: true,
      childList: [
        {
          menuName: "协会简介",
          menuUrl: "list",
          id: "3",
          messageType: 'xhjj',
          show: true,
          childList: []
        },
        {
          menuName: "协会会员",
          menuUrl: "list",
          id: "4",
          messageType: 'xhhy',
          show: true,
          childList: []
        },
        {
          menuName: "协会章程",
          menuUrl: "list",
          id: "5",
          messageType: 'xhzc',
          show: true,
          childList: []
        },
        {
          menuName: "入会须知",
          id: "6",
          menuUrl: "list",
          messageType: 'rhxz',
          show: true,
          childList: []
        },
      ]
    },
    {
      menuName: "关于学会",
      menuUrl: "2",
      id: "7",
      show: true,
      childList: [
        {
          menuName: "学会简介",
          menuUrl: "list",
          id: "8",
          messageType: 'gyxh',
          show: true,
          childList: []
        },
        {
          menuName: "学术研究",
          menuUrl: "list",
          id: "9",
          messageType: 'xsyj',
          show: true,
          childList: []
        },
      ]
    },
    {
      menuName: "通知公告",
      menuUrl: "/list",
      // menuUrl: "3",
      messageType: 'tzgg',
      id: "10",
      show: false,
      // childList: [
      // {
      //   menuName: "通知公告",
      //   menuUrl: "list",
      //   id: "11",
      //   messageType: 'tzgg',
      //   show: true,
      //   childList: []
      // },
      // ]
    },
    {
      menuName: "党建园地",
      id: "26",
      menuUrl: "/list",
      messageType: 'djyw',
      show: false,
      // childList: [
      //   {
      //     menuName: "党建要闻",
      //     id: "27",
      //     menuUrl: "list",
      //     messageType: 'djyw',
      //     show: true,
      //     childList: []
      //   },
      // ]
    },

    {
      menuName: "新闻资讯",
      menuUrl: "4",
      id: "12",
      show: true,
      childList: [
        {
          menuName: "行业资讯",
          id: "13",
          menuUrl: "list",
          messageType: 'hyyw',
          show: true,
          childList: []
        },
        {
          menuName: "金融宣教",
          id: "14",
          menuUrl: "list",
          messageType: 'jrxj',
          show: true,
          childList: []
        },
        {
          menuName: "会员动态",
          id: "15",
          menuUrl: "list",
          messageType: 'hydt',
          show: true,
          childList: []
        },
        // {
        //   menuName: "专题报道",
        //   id: "16",
        //   menuUrl: "list",
        //   messageType: 'ztbd',
        //   show: true,
        //   childList: []
        // },
        {
          menuName: "协会动态",
          id: "17",
          menuUrl: "list",
          messageType: 'xhdt',
          show: true,
          childList: []
        },
      ]
    },
    // {
    //   menuName: "教育培训",
    //   id: "18",
    //   menuUrl: "5",
    //   show: true,
    //   childList: [
    //     {
    //       menuName: "保险网络大学",
    //       id: "19",
    //       menuUrl: "list",
    //       messageType: 'bxwldx',
    //       show: true,
    //       childList: []
    //     },
    //     {
    //       menuName: "讲师组训人员培训",
    //       id: "32",
    //       menuUrl: "list",
    //       messageType: 'jszxrypx',
    //       show: true,
    //       childList: []
    //     },
    //   ]
    // },
    // {
    //   menuName: "消费维权",
    //   id: "20",
    //   menuUrl: "6",
    //   show: true,
    //   childList: [
    // {
    //   menuName: "调解委员会简介",
    //   id: "21",
    //   menuUrl: "list",
    //   messageType: 'tjwyhj',
    //   show: true,
    //   childList: []
    // },
    // {
    //   menuName: "消费维权",
    //   id: "22",
    //   menuUrl: "list",
    //   messageType: 'xfwq',
    //   show: true,
    //   childList: []
    // },
    // {
    //   menuName: "投诉注意事项",
    //   id: "23",
    //   menuUrl: "list",
    //   messageType: 'tjzysx',
    //   show: true,
    //   childList: []
    // },
    // {
    //   menuName: "调解流程",
    //   id: "24",
    //   menuUrl: "list",
    //   messageType: 'tjlc',
    //   show: true,
    //   childList: []
    // },
    {
      menuName: "消费维权",
      id: "25",
      menuUrl: "11",
      messageType: '',
      show: true,
      childList: [
        {
          menuName: "投诉申请",
          id: "26",
          menuUrl: "complaint",
          messageType: 'tjsq',
          show: true,
          childList: []
        },
        {
          menuName: "中华人民共和国消费者权益保护法",
          id: "27",
          menuUrl: "list",
          messageType: 'xfzbhf',
          show: true,
          childList: []
        },
      ]
    },
    // ]
    // },
    // {
    //   menuName: "清廉金融",
    //   id: "28",
    //   menuUrl: "8",
    //   show: true,
    //   childList: [
    {
      menuName: "清廉金融",
      id: "29",
      menuUrl: "list",
      messageType: 'qljr',
      show: false,
      // childList: []
    },
    //   ]
    // },
    // {
    //   menuName: "<安徽保险>",
    //   id: "30",
    //   menuUrl: "9",
    //   show: true,
    //   childList: [
    {
      menuName: "安徽保险",
      id: "31",
      menuUrl: "list",
      messageType: 'ahbx',
      show: false,
      childList: []
    },
    //   ]
    // },
  ]
  mMenuList: any = [
    {
      menuName: "首页",
      id: "1",
      menuUrl: "/mindex",
      messageType: 'sy',
      value: "/mindex",
      show: false,
    },
    {
      menuName: "关于协会",
      menuUrl: "1",
      id: "2",
      show: true,
      childList: [
        {
          menuName: "协会简介",
          menuUrl: "mList",
          id: "3",
          messageType: 'xhjj',
          show: true,
          childList: []
        },
        {
          menuName: "协会会员",
          menuUrl: "mList",
          id: "4",
          messageType: 'xhhy',
          show: true,
          childList: []
        },
        {
          menuName: "协会章程",
          menuUrl: "mList",
          id: "5",
          messageType: 'xhzc',
          show: true,
          childList: []
        },
        {
          menuName: "入会须知",
          id: "6",
          menuUrl: "mList",
          messageType: 'rhxz',
          show: true,
          childList: []
        },
      ]
    },
    {
      menuName: "关于学会",
      menuUrl: "2",
      id: "7",
      show: true,
      childList: [
        {
          menuName: "学会简介",
          menuUrl: "mList",
          id: "8",
          messageType: 'gyxh',
          show: true,
          childList: []
        },
        {
          menuName: "学术研究",
          menuUrl: "mList",
          id: "9",
          messageType: 'xsyj',
          show: true,
          childList: []
        },
      ]
    },
    {
      menuName: "通知公告",
      menuUrl: "mList",
      // menuUrl: "3",
      messageType: 'tzgg',
      id: "10",
      show: false,
      // childList: [
      // {
      //   menuName: "通知公告",
      //   menuUrl: "mList",
      //   id: "11",
      //   messageType: 'tzgg',
      //   show: true,
      //   childList: []
      // },
      // ]
    },
    {
      menuName: "党建园地",
      id: "26",
      menuUrl: "mList",
      messageType: 'djyw',
      show: false,
      // childList: [
      //   {
      //     menuName: "党建要闻",
      //     id: "27",
      //     menuUrl: "mList",
      //     messageType: 'djyw',
      //     show: true,
      //     childList: []
      //   },
      // ]
    },

    {
      menuName: "新闻资讯",
      menuUrl: "4",
      id: "12",
      show: true,
      childList: [
        {
          menuName: "行业资讯",
          id: "13",
          menuUrl: "mList",
          messageType: 'hyyw',
          show: true,
          childList: []
        },
        {
          menuName: "金融宣教",
          id: "14",
          menuUrl: "mList",
          messageType: 'jrxj',
          show: true,
          childList: []
        },
        {
          menuName: "会员动态",
          id: "15",
          menuUrl: "mList",
          messageType: 'hydt',
          show: true,
          childList: []
        },
        // {
        //   menuName: "专题报道",
        //   id: "16",
        //   menuUrl: "mList",
        //   messageType: 'ztbd',
        //   show: true,
        //   childList: []
        // },
        {
          menuName: "协会动态",
          id: "17",
          menuUrl: "mList",
          messageType: 'xhdt',
          show: true,
          childList: []
        },
      ]
    },
    // {
    //   menuName: "教育培训",
    //   id: "18",
    //   menuUrl: "5",
    //   show: true,
    //   childList: [
    //     {
    //       menuName: "保险网络大学",
    //       id: "19",
    //       menuUrl: "mList",
    //       messageType: 'bxwldx',
    //       show: true,
    //       childList: []
    //     },
    //     {
    //       menuName: "讲师组训人员培训",
    //       id: "32",
    //       menuUrl: "mList",
    //       messageType: 'jszxrypx',
    //       show: true,
    //       childList: []
    //     },
    //   ]
    // },
    // {
    //   menuName: "消费维权",
    //   id: "20",
    //   menuUrl: "6",
    //   show: true,
    //   childList: [
    // {
    //   menuName: "调解委员会简介",
    //   id: "21",
    //   menuUrl: "mList",
    //   messageType: 'tjwyhj',
    //   show: true,
    //   childList: []
    // },
    // {
    //   menuName: "消费维权",
    //   id: "22",
    //   menuUrl: "mList",
    //   messageType: 'xfwq',
    //   show: true,
    //   childList: []
    // },
    // {
    //   menuName: "投诉注意事项",
    //   id: "23",
    //   menuUrl: "mList",
    //   messageType: 'tjzysx',
    //   show: true,
    //   childList: []
    // },
    // {
    //   menuName: "调解流程",
    //   id: "24",
    //   menuUrl: "mList",
    //   messageType: 'tjlc',
    //   show: true,
    //   childList: []
    // },
    {
      menuName: "消费维权",
      id: "25",
      menuUrl: "11",
      messageType: '',
      show: true,
      childList: [
        {
          menuName: "中华人民共和国消费者权益保护法",
          id: "27",
          menuUrl: "mList",
          messageType: 'xfzbhf',
          show: true,
          childList: []
        },
      ]
    },
    // ]
    // },
    // {
    //   menuName: "清廉金融",
    //   id: "28",
    //   menuUrl: "8",
    //   show: true,
    //   childList: [
    {
      menuName: "清廉金融",
      id: "29",
      menuUrl: "mList",
      messageType: 'qljr',
      show: false,
      // childList: []
    },
    //   ]
    // },
    // {
    //   menuName: "<安徽保险>",
    //   id: "30",
    //   menuUrl: "9",
    //   show: true,
    //   childList: [
    {
      menuName: "安徽保险",
      id: "31",
      menuUrl: "mList",
      messageType: 'ahbx',
      show: false,
      childList: []
    },
    //   ]
    // },
  ]

  handleOpen(key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose(key, keyPath) {
    console.log(key, keyPath);
  }

  mounted() {
  }
  menuSelected(e, d) {
    // console.log('当前绑定的index值', e)
    // console.log('当前绑定的index与path值', d)
    this.$store.commit("menuName", e);
    this.$store.commit("menuNav", d);
  }
  isMobile(){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
  }

  toRoute(childItem, item) {
    localStorage.removeItem('parentMenu')
    localStorage.setItem("parentMenu", item.menuName);
    localStorage.setItem("childItemMenu", childItem.menuName);
    if (childItem.menuName === '保险网络大学') {
      window.open('https://daxue.iachina.cn/zbxFirstPage');
    }
    this.$router.push({
      path: childItem.menuUrl, query: { 'messageType': childItem.messageType, 'menuName': childItem.menuName }
    })
    window.location.reload();
  }

  oneRoute(item) {
    localStorage.removeItem('parentMenu')
    localStorage.setItem("parentMenu", item.menuName);
    localStorage.setItem("childItemMenu", item.menuName);
    this.$router.push({
      path: item.menuUrl, query: { 'messageType': item.messageType, 'menuName': item.menuName }
    })
    window.location.reload();
  }


  render() {
    return (
      <div class={S.wrapper}>
        {!this.isMobile() &&(
        <div class={S.row}>
          <el-menu
            default-active="0"
            mode="horizontal"
            router={true}
            on-select={(e, d) => this.menuSelected(e, d)}
            background-color="#2662cf"
            text-color="#fff"
          >
            {this.menuList?.map((item: any) => (
              <div style="display:inline-block;">
                {!item.show && (  // 判断一级菜单是否展示
                  <el-menu-item index={item.id} route={item.value}
                    on-click={() => this.oneRoute(item)}>
                    {item.menuName}
                  </el-menu-item>
                )}
                {item.show && (  // 判断一级菜单是否展示
                  <el-submenu index={item.id} route={item.menuUrl} >
                    <template slot="title">
                      <span slot="title">{item.menuName}</span>
                    </template>
                    {item.childList?.map((childItem: any) => (
                      <el-menu-item
                        index={childItem.id}
                        on-click={() => this.toRoute(childItem, item)}  // 传递父级菜单的信息
                      >
                        {childItem.menuName}
                      </el-menu-item>
                    ))}
                  </el-submenu>
                )}
              </div>
            ))}
          </el-menu>
        </div >
        )}
        {this.isMobile() &&(
          <div class={S.mRow}>
          <el-menu
            default-active="0"
            mode="horizontal"
            router={true}
            on-select={(e, d) => this.menuSelected(e, d)}
            background-color="#2662cf"
            text-color="#fff"
          >
            {this.mMenuList?.map((item: any) => (
              <div style="display:inline-block;width:33%;text-align: center;">
                {!item.show && (  // 判断一级菜单是否展示
                  <el-menu-item index={item.id} route={item.value}
                    on-click={() => this.oneRoute(item)}>
                    {item.menuName}
                  </el-menu-item>
                )}
                {item.show && (  // 判断一级菜单是否展示
                  <el-submenu index={item.id} route={item.menuUrl} >
                    <template slot="title">
                      <span slot="title">{item.menuName}</span>
                    </template>
                    {item.childList?.map((childItem: any) => (
                      <el-menu-item
                        index={childItem.id}
                        on-click={() => this.toRoute(childItem, item)}  // 传递父级菜单的信息
                      >
                        {childItem.menuName}
                      </el-menu-item>
                    ))}
                  </el-submenu>
                )}
              </div>
            ))}
          </el-menu>
        </div >
        )}
      </div >
    );
  }
}

export default Menu;
