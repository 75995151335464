import { Vue, Component } from "vue-property-decorator";
import { } from "@/api/index";
import { queryBrowseByPage, plusBrowseNum } from "@/api/index";
import { eventBus } from '@/components/header/index';
import NewPager from "@/components/pagination/index";
import S from "./list.module.scss";
Vue.component("main_pager", NewPager);
@Component
class resList extends Vue {
    activeName: any = ''
    titName: any = ''
    messageType: any = ''
    serch: any = ''
    total = 0
    pageSizes = 9
    page: any = {
        currentPage: 1,
        pageSize: 9
    }
    messageName: any = ''
    childrenMessageName: any = ''
    newsList: any = [
    ]
    menuList: any = [
        {
            menuName: "首页",
            id: 1,
            menuUrl: "/index",
            messageType: 'sy',
            value: "/index",
            show: false,
            childList: [
                {
                    menuName: "视频集锦",
                    menuUrl: "list",
                    id: 35,
                    messageType: 'spjj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "财险会员公司",
                    menuUrl: "list",
                    id: 35,
                    messageType: 'cxhygs',
                    show: true,
                    childList: []
                },
                {
                    menuName: "寿险会员公司",
                    menuUrl: "list",
                    id: 35,
                    messageType: 'sxhygs',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "关于协会",
            menuUrl: "1",
            id: 2,
            show: true,
            childList: [
                {
                    menuName: "协会简介",
                    menuUrl: "list",
                    id: 3,
                    messageType: 'xhjj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会会员",
                    menuUrl: "list",
                    id: 4,
                    messageType: 'xhhy',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会章程",
                    menuUrl: "list",
                    id: 5,
                    messageType: 'xhzc',
                    show: true,
                    childList: []
                },
                {
                    menuName: "入会须知",
                    id: 6,
                    menuUrl: "list",
                    messageType: 'rhxz',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "关于学会",
            menuUrl: "2",
            id: 7,
            show: true,
            childList: [
                {
                    menuName: "学会简介",
                    menuUrl: "list",
                    id: 8,
                    messageType: 'gyxh',
                    show: true,
                    childList: []
                },
                {
                    menuName: "学术研究",
                    menuUrl: "list",
                    id: 9,
                    messageType: 'xsyj',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "通知公告",
            menuUrl: "3",
            id: 10,
            show: true,
            childList: [
                {
                    menuName: "通知公告",
                    menuUrl: "list",
                    id: 11,
                    messageType: 'tzgg',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "新闻咨询",
            menuUrl: "4",
            id: 12,
            show: true,
            childList: [
                {
                    menuName: "行业资讯",
                    id: 13,
                    menuUrl: "list",
                    messageType: 'hyyw',
                    show: true,
                    childList: []
                },
                {
                    menuName: "金融宣教",
                    id: 14,
                    menuUrl: "list",
                    messageType: 'jrxj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "会员动态",
                    id: 15,
                    menuUrl: "list",
                    messageType: 'hydt',
                    show: true,
                    childList: []
                },
                {
                    menuName: "专题报道",
                    id: 16,
                    menuUrl: "list",
                    messageType: 'ztbd',
                    show: true,
                    childList: []
                },
                {
                    menuName: "协会动态",
                    id: 17,
                    menuUrl: "list",
                    messageType: 'xhdt',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "教育培训",
            id: 18,
            menuUrl: "5",
            show: true,
            childList: [
                {
                    menuName: "保险网络大学",
                    id: 19,
                    menuUrl: "list",
                    messageType: 'bxwldx',
                    show: true,
                    childList: []
                },
                {
                    menuName: "讲师组训人员培训",
                    id: 32,
                    menuUrl: "list",
                    messageType: 'jszxrypx',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "消费维权",
            id: 20,
            menuUrl: "6",
            show: true,
            childList: [
                {
                    menuName: "中华人民共和国消费者权益保护法",
                    id: 99,
                    menuUrl: "list",
                    messageType: 'xfzbhf',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解委员会简介",
                    id: 21,
                    menuUrl: "list",
                    messageType: 'tjwyhj',
                    show: true,
                    childList: []
                },
                {
                    menuName: "消费维权",
                    id: 22,
                    menuUrl: "list",
                    messageType: 'xfwq',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解注意事项",
                    id: 23,
                    menuUrl: "list",
                    messageType: 'tjzysx',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解流程",
                    id: 24,
                    menuUrl: "list",
                    messageType: 'tjlc',
                    show: true,
                    childList: []
                },
                {
                    menuName: "调解申请",
                    id: 25,
                    menuUrl: "list",
                    messageType: 'tjsq',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "党建园地",
            id: 26,
            menuUrl: "7",
            show: true,
            childList: [
                {
                    menuName: "党建要闻",
                    id: 27,
                    menuUrl: "list",
                    messageType: 'djyw',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "清廉金融",
            id: 28,
            menuUrl: "8",
            show: true,
            childList: [
                {
                    menuName: "清廉金融",
                    id: 29,
                    menuUrl: "list",
                    messageType: 'qljr',
                    show: true,
                    childList: []
                },
            ]
        },
        {
            menuName: "<安徽保险>",
            id: 30,
            menuUrl: "9",
            show: true,
            childList: [
                {
                    menuName: "<安徽保险>",
                    id: 31,
                    menuUrl: "list",
                    messageType: 'ahbx',
                    show: true,
                    childList: []
                },
            ]
        },
    ]
    mounted() {
        eventBus.$on('serch', serch => {
            this.serch = serch
        });
        this.titName = this.$route.query.menuName;
        this.messageType = this.$route.query.messageType;
        this.getList(this.page.currentPage, this.page.pageSize);
        this.query(this.page.currentPage, this.page.pageSize);
        this.messageName = this.menuList.find(item => item.childList && item.childList.length > 0 && item.childList.some(childItem => childItem.messageType === this.messageType))?.menuName;
        this.childrenMessageName = '';
        this.menuList.forEach(item => {
            if (item.childList && item.childList.length > 0) {
                const childItem = item.childList.find(child => child.messageType === this.messageType);
                if (childItem) {
                    this.childrenMessageName = childItem.menuName;
                }
            }
        });
    }
    getList(currentPage: any, pageSize: any) {
        const this_ = this;
        this.$on('pageChange', (event) => {
            this_.page.currentPage = event.currentPage;
            this_.query(event.currentPage, event.pageSize);
        });
    }

    route(e) {
        if (e.externalLinkUrl == null) {
            e.externalLinkUrl = "11"
        }
        if (e.externalLinkUrl.indexOf("http") != -1) {
            window.open(e.externalLinkUrl)
        } else if (e.externalLinkUrl == null || e.externalLinkUrl !== "") {
            this.plusBrowseNum(e.messageType)
            localStorage.removeItem('parentMenu')
            localStorage.setItem("parentMenu", e.messageType);
            this.$router.push({
                path: 'detail', query: { 'menuName': e.title, 'id': e.id }
            })
        }
    }
    plusBrowseNum(e) {
        const params = {
            messageId: e
        }
        plusBrowseNum(params).then((res: any) => {

        }).catch((err: any) => {

        })
    }
    ret() {
        this.$router.push({
            path: 'index',
            query: { 'messageType': this.messageType, 'menuName': this.childrenMessageName, }
        })
        localStorage.setItem("titName", this.messageName);
    }

    query(page, rows) {
        const params = {
            title: this.serch,
            messageType: this.$route.query.messageType,
            pageIndex: page,
            pageSize: rows
        }
        queryBrowseByPage(params).then((res: any) => {
            this.newsList = res.data.value.records,
                this.total = res.data.value.totalCount
        }).catch((err: any) => {
        })
    }
    render() {
        return (
            <div class={S.wrapper}>
                <div class={S.row}>
                    <div class={S.nav}>
                        <a class={S.span1} on-click={() => this.ret()} >{this.messageName}</a>
                        <span class={S.b}>|</span>
                        <span>{this.childrenMessageName}</span>
                    </div>
                    <div class={S.t}>
                        <h1>{this.titName}</h1>
                    </div>
                    <div class={S.resTabs}>
                        <el-tabs v-model={this.activeName}>
                            <ul class={S.resList}>
                                {this.newsList.map((item: any, index: any) => (
                                    <li on-click={() => this.route(item)} >
                                        <img src={item.coverPicHttp} alt="" />
                                        <span class={S.time}>{item.title}</span>
                                    </li>
                                ))}
                            </ul>
                        </el-tabs>
                        <main_pager current-page={this.page.currentPage} page-sizes={this.pageSizes} pageSize={this.page.pageSize} total={this.total}></main_pager>
                    </div>
                </div>
            </div>
        );
    }
}

export default resList;
