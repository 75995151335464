import { Vue, Component } from "vue-property-decorator";
import { queryBrowseByPage, getBrowseInfo } from "@/api/index";
import S from "./index.module.scss"
export const eventBus = new Vue();
@Component
class Footer extends Vue {
	serch: any = ''
	titName: any = ''
	messageType: any = ''
	Num: any = {}
	mounted() {
		this.getBrowseInfo()
	}
	getBrowseInfo() {
		getBrowseInfo().then((res: any) => {
			this.Num = res.data.value
		}).catch((err: any) => {

		})
	}
	query() {
		this.titName = this.$route.query.menuName;
		this.messageType = this.$route.query.messageType;
		const params = {
			title: this.serch,
			messageType: this.messageType,
			pageIndex: 1,
			pageSize: 10
		}
		queryBrowseByPage(params).then((res: any) => {
			// this.newsList = res.data.value.records
		}).catch((err: any) => {
		})
	}

	getList() {
		eventBus.$emit('serch', this.serch);
		console.log(this.serch);

	}
	isMobile(){
	    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	    return flag
  	}
	render() {
		return (
			<div>
				<div class={S.footer}>
					{!this.isMobile() &&(
					<div class={S.wrapper}>
						<img src={require('../../assets/img/code.png')} alt="" />
						<p class={S.footer_p}>安徽省保险行业协会公众号</p>
						<p class={S.footer_p}><a target="_blank" href="https://beian.miit.gov.cn" style="display: inline;color: rgb(204, 204, 204);">院ICP备15007335号-1</a></p>
						<p class={S.footer_p}>
							<span class={S.visit_item}>今日访问量：<strong>{this.Num.todayNum}</strong></span>
							<span class={S.visit_item}>昨天访问量：<strong>{this.Num.yesterdayNum}</strong></span>
							<span class={S.visit_item}>总访问量：<strong>{this.Num.totalNum}</strong></span>
						</p>
					</div>
					)}
					{this.isMobile() &&(
					<div class={S.mWrapper}>
						<img src={require('../../assets/img/code.png')} alt="" />
						<p class={S.footer_p}>安徽省保险行业协会公众号</p>
						<p class={S.footer_p}><a target="_blank" href="https://beian.miit.gov.cn" style="display: inline;color: rgb(204, 204, 204);">院ICP备15007335号-1</a></p>
						<p class={S.footer_p}>
							<span class={S.visit_item}>今日访问量：<strong>{this.Num.todayNum}</strong></span>
							<span class={S.visit_item}>昨天访问量：<strong>{this.Num.yesterdayNum}</strong></span>
							<span class={S.visit_item}>总访问量：<strong>{this.Num.totalNum}</strong></span>
						</p>
					</div>
					)}
				</div>
			</div>
		);
	}
}

export default Footer;
