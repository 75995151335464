import axios from "axios";
import router from "../router";
// import { get } from "lodash";
/**
 * @description 创建请求实例
 */
const service = axios.create({
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
  timeout: 10000, //5000
  withCredentials: true,
  // baseURL: 'http://itg.test.unipay.10010.com:7105/market/market-mobile/',
  data: {}
});
//请求拦截
service.interceptors.request.use(
  (config: any) => {
    let Authentication = localStorage.getItem('Authentication');
    config.headers.Authentication = Authentication;
    return config;
  },
  (error: any) => {
    // 发送失败
    console.log(error);
    return Promise.reject(error);
  }
);
// 响应拦截器：把从服务器获取的结果进行统一处理
service.interceptors.response.use(
  (response: any) => {
    // 成功：默认认为所有HTTP状态码以2开头
    const dataAxios = response;
    
    // const { code } = dataAxios.data.status;
    const code = dataAxios.data.status;
    const msg = dataAxios.data.msg;
    // 根据 code 进行判断

    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 500:
        alert(msg);
        case 401:
        // if (router.currentRoute.path !== "/") {
        //     router.replace({
        //       // path: "login",
        //       name:'login'
        //     });
        //   }
        case "0000":
          return dataAxios;
        case "1001":
          // if (window.location.hostname === 'learn.10010.com') {
          //   window.location.href = 'https://2i.10010.com/login';
          // } else {
          //   window.location.href = 'http://3000test.10010.com:7112/login/init';
          // }
          console.log("未登录");
          if (router.currentRoute.path !== "/login") {
            router.replace({
              // path: "login",
              name:'login',
              query: { redirect: router.currentRoute.path }
            });
          } else {
            window.location.href = `${process.env.VUE_APP_LOGIN}`;
          }
          // window.location.href = `${process.env.VUE_APP_LOGIN}`;
          break;
        // case "0002":
        //   console.log("无权访问");
        //   break;
        // case "0003":
        //   console.log("必传参数未传");
        //   break;
        // case "1001":
        //   console.log("数据库异常");
        //   break;
        // case "3001":
        //   console.log("模板地址未配置");
        //   break;
        // case "3002":
        //   console.log("模板文件不存在");
        //   break;
        // case "4001":
        //   return dataAxios;
        case "9999":
          return dataAxios;
        default:
          // 不是正确的 code
          // console.log(`${dataAxios.detail}`);
          break;
      }
    }
    return response;
  },
  (error: any) => {
    // 失败：从服务获取到结果，只不过状态码不是2xx；压根就没有从服务器获取到数据；
    if (error.response) {
      // 获取到数据了：根据不同的状态码做不同的处理即可
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        default:
          break;
      }
    } else {
      // 没有获取任何的数据
      if (!window.navigator.onLine) {
        // 断网
        console.log("断网了");
      }
    }
    return Promise.reject(error);
  }
);

export default service;
