// import { Vue, Component, Prop } from "vue-property-decorator";

// import S from "./index.module.scss"
// @Component
// class Pagination extends Vue {
// 	@Prop() readonly pageSize;
// 	@Prop() readonly total;
// 	@Prop() readonly currentPage;
// 	handleSizeChange(val) {
//     console.log(`每页 ${val} 条`);
//   }
//   handleCurrentChange(val) {
//     const pageObject = {
//       currentPage :val,
//       pageSize : this.pageSize,
//       total : this.total
//     };
//     this.$parent.$emit('pageChange',pageObject);
//     console.log(`当前页: ${val}`);
//   }

//   render() {
//     return (
//      	<div class={S.wrapper}>
//        	<div class={S.page}>
//        		<el-pagination
// 			      onSize-change={(e)=> this.handleSizeChange(e)}
// 			      onCurrent-change={(val:any)=> this.handleCurrentChange(val)}
// 			      current-page={this.currentPage}
// 			      page-size={this.pageSize}
// 			      layout="total, prev, pager, next"
// 			      total={this.total}>
// 			    </el-pagination>
// 			    <div class={S.clear}></div>
//        	</div>
//      	</div>
//     );
//   }
// }

// export default Pagination;
import { Vue, Component, Prop } from "vue-property-decorator";

import S from "./index.module.scss"
@Component
class Pagination extends Vue {
	@Prop() readonly pageSize;
	@Prop() readonly total;
	@Prop() readonly currentPage;
	handleSizeChange(val) {
		console.log(`每页 ${val} 条`);
	}
	handleCurrentChange(val) {
		const pageObject = {
			currentPage: val,
			pageSize: this.pageSize,
			total: this.total
		};
		this.$parent.$emit('pageChange', pageObject);
		console.log(`当前页: ${val}`);
	}

	render() {
		return (
			<div class={S.wrapper}>
				<div class={S.page}>
					<el-pagination
						onSize-change={(e) => this.handleSizeChange(e)}
						onCurrent-change={(val: any) => this.handleCurrentChange(val)}
						current-page={this.currentPage}
						page-size={this.pageSize}
						layout="total, prev, pager, next"
						total={this.total}>
					</el-pagination>
					<div class={S.clear}></div>
				</div>
			</div>
		);
	}
}

export default Pagination;