import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import device from '../views/device'
import Home from '../views/home'
import index from '../views/index/index'
import list from '../views/list/list'
import resList from '../views/list/resList'
import detail from '../views/detail/detail'
import search from '../views/serch/search'
import complaint from '../views/list/complaint'
import mindex from '../views/mobile/index'
import mList from '../views/mobile/mList'
import mResList from '../views/mobile/mResList'
import mDetail from '../views/mobile/mDetail'
import mHome from '../views/mHome'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
	    name: 'device',
	    // redirect: '/index',
	    component: device,
	},
	{
		path: '/mHome',
	    name: 'mHome',
	    redirect: '/mindex',
	    component: mHome,
	    children:[
	    	{
	    		path: '/mindex',
			    name: 'mindex',
			    component: mindex,
			},
			{
	    		path: '/mList',
			    name: 'mList',
			    component: mList,
			},
			{
	    		path: '/mResList',
			    name: 'mResList',
			    component: mResList,
			},
			{
	    		path: '/mDetail',
			    name: 'mDetail',
			    component: mDetail,
			},
	    ]
	},
	{
		path: '/Home',
	    name: 'Home',
	    redirect: '/index',
	    component: Home,
	    children:[
	    	{
	    		path: '/index',
			    name: 'index',
			    component: index,
			},
			{
	    		path: '/complaint',
			    name: 'complaint',
			    component: complaint,
			},
			{
	    		path: '/list',
			    name: 'list',
			    component: list,
			},
			{
	    		path: '/resList',
			    name: 'resList',
			    component: resList,
			},
			{
	    		path: '/detail',
			    name: 'detail',
			    component: detail,
			},
			{
	    		path: '/search',
			    name: 'search',
			    component: search,
			},
		]	
	}
]

const router: any = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
   scrollBehavior(to, from, savedPosition) {
			return {
				x: 0,
				y: 0
			}
	}
})

export default router
